import React, { useState } from 'react'
import AnimatedTypeComponent from './components/AnimatedTypeComponent'
import ParticlesBackground from './components/ParticlesBackground'
import ExtLink from './link.svg'

const ContainerComponent = ({ id, onMouseEnter, onMouseLeave, isHovered, experience }) => {
	const { titles, company, start, end, description, href } = experience
	return (
		<a href={href} alt={company} target="_blank" rel="noreferrer"
			onMouseEnter={() => onMouseEnter(id)}
			onMouseLeave={() => onMouseLeave()}
			className='sm:m-2 flex relative transition duration-300 ease-in-out rounded-xl'>
			<div
				className={`w-full h-full absolute -z-10 transition duration-300 ease-in-out border rounded-xl ${isHovered
					? 'border-amber-400 backdrop-blur-md text-white shadow-lg'
					: 'border-slate-800 backdrop-blur-sm text-blue-100'
					} opacity-${isHovered || isHovered === null ? '100' : '50'}`}
			>
			</div>

			<div className='z-10 py-[1px]'>
				<div className="m-[1px] px-4 py-2 sticky top-20  backdrop-blur-sm rounded-xl z-20">
					<h3 className="font-bold text-4xl text-blue-500 hover:text-blue-300 transition">{company}<img className="inline h-[25px] mb-[3px] ml-2" src={ExtLink} alt="Link" /></h3>
					<div className="text-white">{start} - {end}</div>
				</div>
				<div className="sm:flex-1 text-slate-300 my-5 p-4">
					{description}
				</div>

				{titles.map((exp, index) => {
					const { title, start, end, description, skills } = exp

					return (
						<div key={`exp_${index}`} className='p-2 border-slate-700 border rounded-xl backdrop-blur-sm m-2 mt-0 transition hover:bg-slate-800'>
							<div className='flex flex-col sm:flex-row sm:justify-between'>
								<h2 className="sticky top-30 font-bold text-2xl text-orange-500">{title}</h2>
								<div className="sticky top-30 p-2 text-slate-400">{start} - {end}</div>
							</div>
							<div className="flex mt-2 flex-col sm:flex-row">
								<div className="sm:flex-1 text-slate-300 my-5">
									{description}
								</div>
							</div>
							<div className="mt-2">
								{skills.map((skill, index) => (
									<span key={index} className="inline-block bg-slate-700 border-slate-500 border rounded-full px-3 py-1 text-sm font-semibold text-slate-200 mr-2 mb-2">{skill}</span>
								))}
							</div>
						</div>
					)
				})}
			</div>
		</a>
	)
}

const experience = [
	{
		href: "https://relm.house",
		company: "Relm",
		description: (<>I'm the Technical Co-Founder for Relm, a smart-home IoT Company. <br /><br />Relm has undergone an important first step in the startup process, and validated our product against the market - we've got 400+ orders and 600+ v1 devices on the way!</>),
		titles: [
			{
				title: "Founding Engineer",
				start: "Sep 2023",
				end: "Present",
				description: "I built the iOS/Android mobile apps, scalable AWS infrastructure, and build around hard-learned nuances in the IoT industry.",
				skills: ["React Native", "BLE 5.X", "AWS", "React", "Scalability", "Google Analytics", "Customer Satisfaction", "IoT"]
			}
		],
		start: "Sep 2023",
		end: "Present",
		duration: "8 mos",
	}, {
		href: "https://hbhold.com/",
		company: "Hydrobuilder Holdings",
		description: "After the previous company shut its doors, I was in a bind - Hydrobuilder was the first ship I could jump to, and I'm glad I did. I wore many hats here.",
		titles: [
			{
				title: "Integration Engineer",
				start: "Jul 2022",
				end: "Mar 2024",
				description: "As the company grew and acquired more subsidiaries, quick integration became more critical. As the sole integration engineer, I plan, oversee, and implement various service migrations, API integrations, payment and order flow operations between brands, and manage and extend our primary ERP system.",
				skills: ["Acumatica", "C#", ".NET", "JavaScript", "Microsoft IIS", "AWS RDS", "AWS EC2", "APIs", "Server Administration"]
			}, {
				title: "Full Stack Engineer",
				start: "Jan 2022",
				end: "Jul 2022",
				description: "A few months in, the dust had settled, so to speak. I was offered the salaried position and brought onboard. I joined the front-end team working on a Magento eCommerce store, and occasionally provided backend services to integrate with other platforms.",
				skills: ["PHP", "Cloudflare", "Acumatica", "Magento", "Salesforce", "JavaScript", "Bash", "Python", "OCR"]
			}, {
				title: "Software Engineer, Contract",
				start: "Dec 2021",
				end: "Jan 2022",
				description: "I was hired along with a team of consultants. Our goal was to provide a set of helping hands for the existing teams and brands that were recently acquired.",
				skills: ["PHP", "Google Cloud", "Magento", "Salesforce", "JavaScript"]
			},
		],
		start: "Dec 2021",
		end: "Mar 2024",
		duration: "2 yrs 6 mos",
	}, {
		href: "https://www.crunchbase.com/organization/iaso-ai",
		company: "Iaso AI",
		description: "Iaso's mission was to build out a platform for grading participants of telehealth encounters - how much eye contact, backdrop noise, appropriate emotional responses to items discussed, etc. Cool idea, difficult to execute.",
		titles: [
			{
				title: "Senior Backend Engineer",
				start: "Jul 2021",
				end: "Dec 2021",
				description: (<>As Senior Backend Engineer, I designed and implemented an AWS workflow that facilitated the capture and processing of Telehealth encounters between Primary Care Physicians and their patients. Ultimately, being hire #3 was too risky - Iaso is now owned by a Turkish firm and my wife and I had to move in with my parents (lol).<br /><br />It was still a valuable experience.</>),
				skills: ["TypeScript", "JavaScript", "CLI Tooling", "AWS SDK", "AWS CloudFormation", "HIPAA", "React Mentor"]
			}
		],
		start: "Jul 2021",
		end: "Dec 2021",
		duration: "6 mos",
		location: "Denver, Colorado, United States",
	}, {
		href: "https://www.goldenhippo.com/",
		company: "Golden Hippo Media",
		description: "Golden Hippo Media had all the hallmarks of a great company - glowing reviews, friendly teams, in-touch C-Suite. Had the technologies they used lined up with the listing I was referred to, I would have been much happier there.",
		titles: [{
			title: "Full Stack Software Engineer",
			start: "Jan 2021",
			end: "Jun 2021",
			description: "My time at Golden Hippo was short, but effective. After onboarding, I identified and drastically improved technical debt that was strewn across 14 brands. In my first month, I had designed and deployed a CMS to feed dynamic content to an Angular SPA, removed all of the marketing team's hard-coded AB Tests, and saw an immediate decrease in draw time from 7-10 seconds down to 10-20 milliseconds. Driven traffic cost and advertising spend decreased by $200,000 per day.",
			skills: ["React", "AWS DynamoDB", "AWS API Gateway", "AWS IAM", "AWS S3", "Heroku", "Postgres", "Bash"]
		}],
		start: "Jan 2021",
		end: "Jun 2021",
		duration: "6 mos",
		location: "Woodland Hills, California, United States",
	},{
		href: "https://www.pura.com",
		company: "Pura",
		description: "Pura was my white whale, the epitome of awesome opportunities. I was hire #13 at this early stage IoT startup, and we grew from 400 to 4+ million active devices during my tenure. I am eager to recreate this experience.",
		titles: [
			{
				title: "Senior Full Stack Software Engineer",
				start: "Jan 2020",
				end: "Jan 2021",
				description: "I both maintained and extended our aging, debt laden silo and continued porting modules to and designing new services for AWS, directly facilitating growth from 400 of our original devices to supporting telemetry of 4+ million. I also spearheaded greenfield project demos for potential clients, such as realtime usage tools for NEST Fragrances, and improving customer and investor experience by implementing a branded TV wall.",
				skills: ["JavaScript", "TypeScript", "React", "AWS IoT Core", "AWS Cognito", "Firebase", "AWS Athena", "10+ AWS Services"]
			}, {
				title: "Full Stack Software Engineer",
				start: "Dec 2018",
				end: "Jan 2020",
				description: "First item on the docket was to optimize and extend the existing silo - I broke out the individual modules like scheduling and device control into their own microservices, implemented multithreaded and parallel communications, and built a prototype Alexa skill.",
				skills: ["Node.js", "Heroku", "JavaScript", "React", "Particle API", "Firebase", "Alexa Skills"]
			},
		],
		start: "Dec 2018",
		end: "Jan 2021",
		duration: "2 yrs 2 mos",
		location: "Orem, UT",
	},{
		href: "https://www.crunchbase.com/organization/heartbeat-3",
		company: "Heartbeat",
		description: "Heartbeat attempted to go from culting ad agency to DIY Ad Platform. They specialized in word of mouth marketing through social media channels.",
		titles: [{
			title: "Full Stack Software Engineer",
			start: "Oct 2017",
			end: "Dec 2018",
			description: "Heartbeat was my first experience at a startup! I joined during their TechStars LA run, and helped build out campaign tooling, various trackers, reports, extended our React app, managed our EC2 instances, and helped implement our self-managed marketing-as-a-service product.",
			skills: ["JavaScript", "React", "PHP", "AWS EC2", "AWS IAM", "Bash", "Linux"]
		}],
		start: "Oct 2017",
		end: "Dec 2018",
		duration: "1 yr 3 mos",
		location: "Venice Beach, CA",
	},{
		href: "https://intermetro.net",
		company: "InterMetro",
		description: "I left working at a toy company to go join the grown-up world of telecoms. It was about as exciting as you'd imagine.",
		titles: [
			{
				title: "Software Engineer I, Team Lead",
				start: "Aug 2017",
				end: "Oct 2017",
				description: "I was deemed trustworthy and competant, and given a small team to build out support tools for our sales floor. We were also tasked with sweeping and stocking the warehouse. As engineers.",
				skills: ["PHP", "JavaScript", "CentOS", "SIP", "VoIP", "Zend"],
			}, {
				title: "Software Engineer I",
				start: "Jun 2017",
				end: "Aug 2017",
				description: "I spent 30 days going through proctored development on the Manager's air-gapped desktop - no computer issued at this point. I built and wired cubicles, made coffee, cleaned restrooms, and occasionally fixed printers and phones in the building. This was nothing like \"The office.\"",
				skills: ["PHP", "JavaScript", "HTML", "CSS", '"Kevin Framework" (Heavily modified Zend)'],
			},
		],
		start: "Jun 2017",
		end: "Oct 2017",
		duration: "5 mos",
	},{
		company: "Jakks Pacific",
		href: "https://jakks.com",
		description: "JAKKS was everything you'd want out of a corporate suit-and-tie job. I made cubicle friends, learned some git, ate at the food trucks, and sat in hours of traffic every day. 10/10 would recommend.",
		titles: [{
			title: "Jr. Web Developer",
			start: "Jun 2016",
			end: "Jun 2017",
			description: "I joined the engineering team to aid in a system wide upgrade of our intranet front-end. Remember the days of submit form -> refresh page? Ajax to the rescue.",
			skills: ["PHP", "JavaScript", "HTML", "jQuery", "MySQL", "Git", "Vagrant"]
		}, {
			title: "Sys Admin",
			start: "Aug 2015",
			end: "Jun 2016",
			description: "I helped manage and extend our in-house intranet. Mostly things like new reports for various division heads, but occasionally provided endpoints for queries to our front-end team.",
			skills: ["PHP", "MySQL", "Git"]
		}],
		start: "Aug 2015",
		end: "Jun 2017",
		duration: "1 yr 11 mos",
		location: "Santa Monica, CA",
	}
]

const types = [
	{ type: "Full Stack", fontClass: "" },
	{ type: "Fun", fontClass: "" },
	{ type: "Backend", fontClass: "" },
	{ type: "Motorcycle", fontClass: "" },
	{ type: "Lego", fontClass: "" },
	{ type: "Frontend", fontClass: "" },
	{ type: "DevOps", fontClass: "" },
	{ type: "Integration", fontClass: "" },
]

const LandingPage = () => {
	const [hoveredItem, setHoveredItem] = useState(null)

	return (<div className="flex flex-col lg:flex-row justify-between items-start p-6 2xl:mx-60 h-full pt-32">
		<div className="text-white w-full lg:w-1/2 relative p-4 lg:sticky top-0 h-full backdrop-blur-sm">
			<h1 className="font-bold text-7xl mb-2 text-blue-600">Andrew Ballard</h1>
			<AnimatedTypeComponent types={types} />

			<div className='text-lg w-full py-2 sm:py-0 sm:w-2/3 text-slate-300'>
				My name's Andy, and I'm pretty cool. I do web things for people with money.
				<br />
				<br />
				I also do apps, infrastructure, have too many guitars, and ride motorcycles.
				<br />
				<br />
				<div className='flex flex-col mb-8'>
					<a key="Resume" className='inline-block underline py-2 cursor-pointer text-xl font-semibold text-orange-400 hover:text-orange-300 transition' href='./resume.pdf' target='_blank'>Resume</a>
					<a key="LinkedIn" className='inline-block underline py-2 cursor-pointer text-xl font-semibold text-orange-400 hover:text-orange-300 transition' alt="Andrew Ballard's LinkedIn" rel="noreferrer" href='https://www.linkedin.com/in/andrew-ballard-955aa553' target='_blank'>LinkedIn</a>
				</div>
				<h2>Side Stuff</h2>
				<div className='flex flex-col border-t text-md text-slate-300'>
					<p><a key="Denim" className="hover:text-orange-300 transition inline-block underline py-2 cursor-pointer font-semibold text-orange-400" href="https://www.with-denim.com" rel="noreferrer" target="_blank">Denim Web Design</a> - Pay Me For Things</p>
					<p><a key="Shoot Things" className="hover:text-orange-300 transition inline-block underline py-2 cursor-pointer font-semibold text-orange-400" rel="noreferrer" target="_blank" href="https://www.shoot-things.com">Shoot Things</a> - Arcade Shooter</p>
					<p><a key="Resume to Porfolio" className="hover:text-orange-300 transition inline-block underline py-2 cursor-pointer font-semibold text-orange-400" href="https://www.resume-to-portfolio.com" rel="noreferrer" target="_blank">Resume to Portfolio</a> - Paid Service</p>
					<p><a key="Studies" className="hover:text-orange-300 transition inline-block underline py-2 cursor-pointer font-semibold text-orange-400" href="https://mystudiesbuddy.com" rel="noreferrer" target="_blank">My Studies Buddy</a> - Client</p>
					<p><a key="Learn to Tutor" className="hover:text-orange-300 transition inline-block underline py-2 cursor-pointer font-semibold text-orange-400" href="https://learntotutor.com" rel="noreferrer" target="_blank">Learn to Tutor</a> - Snagged Client (<a className="underline" href="https://tutor-demo.netlify.app" target="_blank" rel="noreferrer">Demo</a>)</p>
				</div>
			</div>
		</div>

		<div className="flex flex-col w-full lg:w-1/2 pt-20 md:pt-0 h-full z-10">
			<div>
				<div className="font-bold sticky top-0 z-20 text-5xl mb-4 text-orange-500 w-full backdrop-blur-md p-4 sm:p-5">History</div>
				<div className="sm:m-2 p-4 rounded-xl text-slate-300 backdrop-blur-sm border border-slate-800">
					In 2002, my dad stuck me in a chair close enough to see the CRT he was working on. Today we'd be making a Pacman rip-off.
					The game itself was all vanilla Javascript, HTML 4, and CSS 2 (lol).
					<br />
					<br />
					What he hoped to instill in me was a newfound interest in technology, but unfortunately for him all I cared about was McDonald's and my Nintendo64.
					It was nearly a decade later that I finally picked up programming, and with it came all the trope opinions, like Vim over Emacs and tabs over spaces.
					<br />
					<br />
					Another decade later, and I'm a professional engineer, with a job and insurance and everything. I've worked in a handful of industries - Toys, Telecoms, both big-budget and small agency Advertising, IoT, Telehealth AI/ML, Native App Development, and most recently, Industrial Agriculture.
					<br />
					<br />
					Sure, most of my personal projects don't see the traffic I'd like, but professionally, my work has supported millions of DAUs, facilitated 100s of millions of dollars in sales, erased mountains of technical debt, and given me a unique perspective on software engineering as a whole.
				</div>
			</div>

			<div className="font-bold sticky top-0 z-30 text-5xl text-orange-500 w-full backdrop-blur-sm p-4 sm:p-5">Experience</div>
			<div className="space-y-4">
				{experience.map((exp, index) => (
					<ContainerComponent
						key={`exp_${index}`}
						id={`id_${index}`}
						onMouseEnter={() => setHoveredItem(index)}
						onMouseLeave={() => setHoveredItem(null)}
						isHovered={hoveredItem === index}
						experience={exp}
					/>
				))}
			</div>
		</div>
	</div>)
}

const App = () => {
	return (
		<>
			<ParticlesBackground />
			<LandingPage />
		</>
	)
}

export default App