import React, { useState, useEffect, useRef } from 'react'

const AnimatedTypeComponent = ({ types }) => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const nextIndex = (currentIndex + 1) % types.length
	const [animationState, setAnimationState] = useState('entering') // "entering", "visible", "exiting"
	const containerRef = useRef(null)

	useEffect(() => {
		const cycleTypes = () => {
			if (animationState === 'entering') {
				setAnimationState('visible')
				setTimeout(() => setAnimationState('exiting'), 50)
			} else if (animationState === 'exiting') {
				setCurrentIndex(nextIndex)
				setAnimationState('entering')
			}
		}

		const animationTimer = setTimeout(cycleTypes, animationState === 'visible' ? 0 : 3000)
		return () => clearTimeout(animationTimer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [animationState, currentIndex, types.length])

	useEffect(() => {
		if (containerRef.current) {
			const currentTypeElement = containerRef.current.querySelector(`[data-index="${currentIndex}"]`)
			if (currentTypeElement) {
				containerRef.current.style.width = `${currentTypeElement.offsetWidth}px`
			}
		}
	}, [currentIndex, types.length, animationState])

	return (
		<div className="flex flex-wrap flex-col sm:flex-row items-left break-words space-y-2 md:space-y-0 md:space-x-2">
			<div className="font-bold text-4xl py-2">Senior</div>
			<div ref={containerRef} className="flex items-center md:mx-2 p-0 transition-width duration-1000" style={{ width: 'auto', height: 'auto', minWidth: '50px' }}>
				{types.map((type, index) => (
					<div
						key={type.type}
						data-index={index}
						className={`font-bold text-4xl break-words absolute transition-opacity m0 py-2 duration-1000 ${type.fontClass}  ${index === currentIndex ? 'opacity-100' : 'opacity-0'
							}`}
						style={{
							fontFamily: type.font,
							transition: 'opacity 0.5s, width 0.5s',
							willChange: 'opacity, width',
						}}
					>
						{type.type}
					</div>
				))}
			</div>
			<div className="font-bold text-4xl py-2 transition-all duration-1000">Engineer</div>
		</div>
	)
}

export default AnimatedTypeComponent
