import { useEffect, useState } from "react"
import Particles, { initParticlesEngine } from "@tsparticles/react"
import { loadSlim } from "@tsparticles/slim" // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.

const options = {
	background: {
		color: {
			value: "#0f172a",
		},
	},
	fpsLimit: 120,
	interactivity: {
		events: {
			onClick: {
				enable: false,
				mode: "push",
			},
			onHover: {
				enable: true,
				mode: "repulse",
			},
		},
		modes: {
			repulse: {
				distance: 200,
				duration: 1,
				speed: 5,
				maxSpeed: 5,
				easing: "ease-out-quad",
			},
		},
	},
	particles: {
		color: {
			value: "#ffffff",
			opacity: 0.3
		},
		links: {
			color: "#ffffff",
			distance: 200,
			enable: true,
			opacity: 0.4,
			width: 1,
		},
		move: {
			direction: "top",
			enable: true,
			random: true,
			speed: 0.8,
			straight: false,
		},
		number: {
			density: {
				enable: true,
			},
			value: 60,
		},
		opacity: {
			value: 0.3,
		},
		shape: {
			close: true,
			fill: true,
			options: {},
			type: "circle"
		},
		size: {
			value: { min: 0.1, max: 2.5 },
		},
	},
	detectRetina: true,
}

const ParticlesBackground = () => {
	const [init, setInit] = useState(false)
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadSlim(engine)
		}).then(() => {
			setInit(true)
		})
	}, [])

	return (<div className={`absolute w-full h-full bg-slate-900 ${init ? 'opacity-100' : 'opacity-0'} transform-all duration-1000`}>
		{ init && <Particles
			id="tsparticles"
			options={options}
		/>
}
	</div >)
}

export default ParticlesBackground
